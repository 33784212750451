import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/uireact/uireact/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props } from 'docz';
import { UiSpacing, TextSize } from '@uireact/foundation';
import { UiIcon } from '@uireact/icons';
import * as packageJson from '../package.json';
import Playground from '../../../src/Playground';
import { UiBadge } from '../src/';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">






    <h1 {...{
      "id": "uibadge"
    }}>{`UiBadge`}</h1>
    <sup>
  <a href="https://github.com/inavac182/ui-react/blob/main/packages/badge/" target="_blank">
    v{packageJson.version}
  </a>
    </sup>
    <blockquote>
      <p parentName="blockquote">{`Component mainly used to show badges to categorize information`}</p>
    </blockquote>
    <h2 {...{
      "id": "installation"
    }}>{`Installation`}</h2>
    <blockquote>
      <p parentName="blockquote">{`npm i @uireact/foundation @uireact/badge`}</p>
    </blockquote>
    <h2 {...{
      "id": "uibadge-1"
    }}>{`UiBadge`}</h2>
    <Playground __position={1} __code={'<UiSpacing margin={{ block: \'three\' }}>\n  <UiBadge size=\"xlarge\">✈️ Primary</UiBadge>\n</UiSpacing>\n<UiSpacing margin={{ block: \'three\' }}>\n  <UiBadge category=\"secondary\" size=\"large\">\n    <UiIcon icon=\"BadgeCheck\" /> Secondary with icon\n  </UiBadge>\n</UiSpacing>\n<UiSpacing margin={{ block: \'three\' }}>\n  <UiBadge category=\"tertiary\" size=\"regular\">\n    🚀 Tertiary\n  </UiBadge>\n</UiSpacing>\n<UiSpacing margin={{ block: \'three\' }}>\n  <UiBadge category=\"positive\">✅ Positive</UiBadge>\n</UiSpacing>\n<UiSpacing margin={{ block: \'three\' }}>\n  <UiBadge category=\"warning\" size=\"xsmall\">\n    🚨 Warning\n  </UiBadge>\n</UiSpacing>'} __scope={{
      props,
      DefaultLayout,
      Props,
      UiSpacing,
      TextSize,
      UiIcon,
      packageJson,
      Playground,
      UiBadge,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiSpacing margin={{
        block: 'three'
      }} mdxType="UiSpacing">
    <UiBadge size="xlarge" mdxType="UiBadge">✈️ Primary</UiBadge>
  </UiSpacing>
  <UiSpacing margin={{
        block: 'three'
      }} mdxType="UiSpacing">
    <UiBadge category="secondary" size="large" mdxType="UiBadge">
      <UiIcon icon="BadgeCheck" mdxType="UiIcon" /> Secondary with icon
    </UiBadge>
  </UiSpacing>
  <UiSpacing margin={{
        block: 'three'
      }} mdxType="UiSpacing">
    <UiBadge category="tertiary" size="regular" mdxType="UiBadge">
      🚀 Tertiary
    </UiBadge>
  </UiSpacing>
  <UiSpacing margin={{
        block: 'three'
      }} mdxType="UiSpacing">
    <UiBadge category="positive" mdxType="UiBadge">✅ Positive</UiBadge>
  </UiSpacing>
  <UiSpacing margin={{
        block: 'three'
      }} mdxType="UiSpacing">
    <UiBadge category="warning" size="xsmall" mdxType="UiBadge">
      🚨 Warning
    </UiBadge>
  </UiSpacing>
    </Playground>
    <h3 {...{
      "id": "props"
    }}>{`Props`}</h3>
    <Props of={UiBadge} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      